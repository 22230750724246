var data = []
var senhaInput = $('.form__field.abertura')
if(senhaInput) {
	$.ajax({
		url: '/escolas.json',
		success: function(result){
			data = result.data
		}
	});


	$(".open-modal").on('click', function(){
		var idmodal= $(this).attr("data-id");
		$("#"+idmodal+", .overlay-pagina-inicial").show();
	});

	$(".close-modal").on('click',function(){
		$(".overlay-pagina-inicial").hide();
		$(".modal-pagina-abertura").hide();
	});

	$(".envia-senha").on('click',function(){
		var senha = senhaInput.val()
		var escola = data.find(escola => escola.senha === senha)
		$('#modal-senha-erro').remove()

		if (escola) {
			window.location.href = '/bem-vindo'
			$(".overlay-pagina-inicial").hide();
			$(".modal-pagina-abertura").hide();
			localStorage.setItem('escolaSelecionada', JSON.stringify(escola))

		} else {
			senhaInput.after('<div id="modal-senha-erro" style="color: red;margin-top: 10px;">Senha inválida!</div>')
		}
	});
}

