$(function(){
	$('.navbar-toggle, nav').click(function(){
		$('.navbar-toggle').toggleClass('navbar-on');
		$('nav').fadeToggle();
		$('nav').removeClass('nav-hide');
	});
});

$(function(){
	$('.button-menu').click(function(){
		$('.button-menu').toggleClass('button-menu-01');
	});
});

// NAVIGATION SCROLL

$(document).scroll(function() {
	var y = $(this).scrollTop();
	if (y > 20) {
		$('.navigation').fadeIn();
	} else {
		$('.navigation').fadeOut();
	}
});