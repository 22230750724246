
$('#owl-one').owlCarousel({
	dots: false,
	center: true,
	items: 2,
	loop: false,
	margin: 5,
	nav: true,
	rewind: true,
	responsive:{
		0: {
			items: 1,
			nav: true,
			rewind: true
		},
		769:{
			items: 2,
			nav: true
		}
	}
});
$('.wp-block-gallery').owlCarousel({
	dots: false,
	center: true,
	items: 2,
	loop: false,
	margin: 5,
	nav: true,
	rewind: true,
	responsive:{
		0: {
			items: 1,
			nav: true,
			rewind: true
		},
		769:{
			items: 2,
			nav: true
		}
	}
}); 
$('#owl-two').owlCarousel({
	dots: false,
	center: false,
	items: 2.5,
	loop: true,
	margin: 10,
	nav: true,
	responsive:{
		0: {
			items: 1,
			nav: true,
			rewind: true
		},
		769:{
			dots: false,
			center: false,
			items: 2.5,
			loop: true,
			margin: 10,
			nav: true
		}
	}
});