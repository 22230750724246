var images = [
	'/wp-content/uploads/2022/02/logo-preto.svg',
	'/wp-content/uploads/2022/02/logo-rosa.svg',
	'/wp-content/uploads/2022/02/logo-verde.svg',
	'/wp-content/uploads/2022/02/logo-amarelo-1.svg',
	'/wp-content/uploads/2022/02/logo-azul.svg'
	];

	if (document.getElementById('logo')) {
		document.getElementById('logo').src = images[Math.round((Math.random() * (images.length-1)))];
	}
