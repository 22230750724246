var escolas = {}
var estados = [
  { name: "Acre", id: "AC" },
  { name: "Alagoas", id: "AL" },
  { name: "Amapá", id: "AP" },
  { name: "Amazonas", id: "AM" },
  { name: "Bahia", id: "BA" },
  { name: "Ceará", id: "CE" },
  { name: "Distrito Federal", id: "DF" },
  { name: "Espírito Santo", id: "ES" },
  { name: "Goiás", id: "GO" },
  { name: "Maranhão", id: "MA" },
  { name: "Mato Grosso", id: "MT" },
  { name: "Mato Grosso do Sul", id: "MS" },
  { name: "Minas Gerais", id: "MG" },
  { name: "Pará", id: "PA" },
  { name: "Paraíba", id: "PB" },
  { name: "Paraná", id: "PR" },
  { name: "Pernambuco", id: "PE" },
  { name: "Piauí", id: "PI" },
  { name: "Rio de Janeiro", id: "RJ" },
  { name: "Rio Grande do Norte", id: "RN" },
  { name: "Rio Grande do Sul", id: "RS" },
  { name: "Rondônia", id: "RO" },
  { name: "Roraima", id: "RR" },
  { name: "Santa Catarina", id: "SC" },
  { name: "São Paulo", id: "SP" },
  { name: "Sergipe", id: "SE" },
  { name: "Tocantins", id: "TO" },
];

var cidades = {}
var cidadeId = null

var $stateSelect = $('.state-select--js')
var $citySelect = $('.city-select--js')
var $schoolSelect = $('.school-select--js')

function formataNomeEscola (escola) {
	escolas[escola.cod] = escola
	return [escola.nome, '-', escola.cidade, '-', escola.estado].join(' ')
}

$stateSelect.select2()
$stateSelect.append(
  estados.map((item) => `<option value="${item.id}">${item.name}</option>`).join('')
)
$stateSelect.on('change', function(event) {
  console.log(event.target.value)
  var estado = event.target.value
  $citySelect.val('')

  if (!cidades[estado]) {
    $.ajax({
      url: 'https://apijornada.pipocadigital.com.br/v1/opendata/' + estado,
      contentType: "application/json",
      dataType: "json",
      success: function (response) {
        cidades[estado] = response;
        loadCitiesOptions(estado)
      },
    })
  } else {
    loadCitiesOptions(estado)
  }
})

$citySelect.select2()
$citySelect.on('change', function(event) {
  cidadeId = event.target.value
  $schoolSelect.attr('disabled', false)
})

function loadCitiesOptions (estado) {
  var cidadesFirstOption = '<option value="">Selecione a Cidade</option>'
  var cidadesOptions = cidades[estado].map((item) => `<option value="${item.id}">${item.name}</option>`)

  $citySelect.html(
    [cidadesFirstOption, cidadesOptions].join('')
  )

  if(cidadesOptions.length > 0) {
    $citySelect.attr('disabled', false)
  }

  $citySelect.select2()
}

$schoolSelect.select2({
  minimumInputLength: 4,
  language: {
    inputTooShort: function () {
      return 'Digite nome da escola...';
    },
    searching: function () {
      return 'Buscando...';
    },
    noResults: function () {
      return 'Nenhum resultado encontrado'
    }
  },
  ajax: {
    url: 'https://apijornada.pipocadigital.com.br/v1/opendata',
    dataType: 'json',
    data: function (params) {
      var query = {
        nome: params.term,
        cidade: cidadeId
      }

      return query;
    },
    processResults: function (data) {
      return {
        results: data[1].map((item) => ({
          id: item.cod,
          text: formataNomeEscola(item),
        }))
      }
    },
  }
});

$schoolSelect.on('change', function() {
	var localEscola = $('#local-escola')
	if (localEscola) {
		localEscola.remove()
	}
	var escola = escolas[$(this).val()]
	$('#tradingName').val(escola.nome)
})

$('.registerForm--js').on('submit', function (event) {
  event.preventDefault();
  var form = $(event.target);
  var formData = {};
  form.serializeArray().forEach(function(item) {
    formData[item.name] = item.value
  });

  form.addClass('submitting');

  $.ajax({
    method: 'POST',
    url: 'https://apijornada.pipocadigital.com.br/v1/register',
    contentType: "application/json",
    dataType: "json",
    data: JSON.stringify(formData),
    success: function () {
      $('#registerModal-success--js, .overlay').show();
      event.target.reset();
    },
    error: function () {
      $('#registerModal-error--js, .overlay').show();
    },
    complete: function () {
      form.removeClass('submitting');
    }
  })
});

