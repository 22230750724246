$(document).ready(function(){
	$('#img-responsive').on( "click", function() {
		$('#cool').toggleClass('maxSize')
	});
});

$('#gallery').lightSlider({
	gallery:true,
	item:2,
	thumbItem:5,
	slideMargin: 4,
	speed:600,
	auto:false,
	loop:false,
	dots:true,

	enableTouch:true,
	enableDrag:true,
	freeMove:true,
	swipeThreshold: 40,

	responsive : []
});

$('#video').lightSlider({
	gallery:true,
	item:1,
	thumbItem:5,
	slideMargin: 0,
	speed:600,
	auto:false,
	loop:false,
	dots:true,

	enableTouch:true,
	enableDrag:true,
	freeMove:true,
	swipeThreshold: 40,

	responsive : []
});