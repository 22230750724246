$('#slide').owlCarousel({
	dots: false,
	center: true,
	items: 1,
	loop: true,
	margin: 5,
	nav: true,
	rewind: true,
});
$('#slide-mobile').owlCarousel({
	dots: false,
	center: true,
	items: 1,
	loop: true,
	margin: 5,
	nav: true,
	rewind: true,
});
$('#owl-one').owlCarousel({
	dots: false,
	items: 3,
	margin: 10,
	nav: true,
	responsive:{
		0: {
			items: 1.5,
			margin: 10,
			nav: true,
			rewind: true
		},
		769:{
			items:  3,
			margin: 10,
			center: false,
			rewind: false,
			nav: false,
			loop: false
		}
	}
});
$('#owl-two').owlCarousel({
	dots: false,
	center: false,
	items: 2.5,
	loop: true,
	margin: 10,
	nav: true,
	responsive:{
		0: {
			items: 1,
			nav: true,
			rewind: true
		},
		769:{
			dots: false,
			center: false,
			items: 3.2,
			loop: true,
			margin: 10,
			nav: true
		}
	}
});
$('#owl-three').owlCarousel({
	stagePadding: 50,
	dots:false,
	loop:false,
	rewind: true,
	margin:10,
	nav:true,
	responsive:{
		0:{
			items:1
		},
		600:{
			items:3
		},
		1000:{
			items:5
		}
	}
})