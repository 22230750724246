var escolaSelecionada
var bemVindoSection = document.getElementsByClassName('bem-vindo')[1]
var noficationURL = '/notification.php'

var getMentorItem = (nome, email, foto, ePrimeiro) => `
	<div class="moldura center ${ePrimeiro ? '' : 'mt-lg-5 mt-4'}">
		<img class="px-4 py-4" src="${foto}">
	</div>
	<ul>
		<li class="d-flex mt-2">
			<img class="pr-2" src="/wp-content/uploads/2022/03/infor-azul.svg">
			<a href="mailto:${email}" class="mt-2">${nome}</a>
		</li>
		<li class="d-flex mt-2">
			<img class="pr-2" src="/wp-content/uploads/2022/03/infor-rosa.svg">
			<a href="mailto:${email}" class="mt-2">${email}</a>
		</li>
	</ul>
`

const getMetorEmail = (nome, email) => `
	<a href="mailto:${email}" class="mt-2">${nome}</a>
`

try {
	if (bemVindoSection) {
		escolaSelecionada = JSON.parse(localStorage.getItem('escolaSelecionada'))
		document.getElementById('escola').innerHTML = escolaSelecionada.escola.nome
		document.getElementById('diretor').innerHTML = escolaSelecionada.diretor

		document.getElementById('email-mentores').innerHTML =  escolaSelecionada
			.mentores
			.map((mentor, index) => getMetorEmail(
				mentor.nome,
				mentor.email
			))
			.join(' ou ')

		document.getElementById('mentores').innerHTML = escolaSelecionada
			.mentores
			.map((mentor, index) => getMentorItem(
				mentor.nome,
				mentor.email,
				mentor.foto,
				index === '0'
			))
			.join('')

		bemVindoSection.style.opacity = 1

		$.ajax({
			url: `${noficationURL}?name=${escolaSelecionada.diretor}&escola=${escolaSelecionada.escola.nome}&senha=${escolaSelecionada.senha}`,
			contentType: 'application/json',
      dataType: 'json',
		})

	}
} catch(e) {
	console.error(e)
	bemVindoSection.innerHTML = '<span style="color: red">Um erro inesperado aconteceu, as informações da escola não foram encontradas!</span>'
	bemVindoSection.style.opacity = 1
}
